import React, { Component } from 'react';
import ContactForm from './contact_form'
import Map from '../../shared/map/map'
import BlueDiv from '../../shared/blue_div/index'
import styles from '../../styles/contactus/contact_us.module.css'
import { likedinUrl , twitterUrl , fbUrl , companyBlackLogo  } from '../../constants/index'
import logo from '../../images/logo.png'



var date = new Date();

var p_date = new Date(date.getTime() - (10 * 24 * 60 * 60 * 1000));
var e_date = new Date(date.getTime() + (20 * 24 * 60 * 60 * 1000));

var job_post_date = `${p_date.getDate()} / ${p_date.getMonth() + 1} / ${p_date.getFullYear()}`;
var job_end_date = `${e_date.getDate()} / ${e_date.getMonth() + 1} / ${e_date.getFullYear()}`;


const team = [
    {
        name : 'Naveed Ahmed Javed' ,
        email : 'naveed@7ctech.com' ,
        position : 'CEO',
        image : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625584230/website-Images/person_odrquo.jpg'
    },

    {
        name : 'Obaid Nizami' ,
        email : 'obaid@7ctech.com' ,
        position : 'PMO',
        image : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625584230/website-Images/person_odrquo.jpg'
    },

    {
        name : 'Mohi uddin' ,
        email : 'mohiudeen@7ctech.com' ,
        position : 'Team Lead',
        image : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625584230/website-Images/person_odrquo.jpg'
    },

    {
        name : 'Faheem' ,
        email : 'fahim@7ctech.com' ,
        position : 'Head of SQA',
        image : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625584230/website-Images/person_odrquo.jpg'
    }
]


class Contact extends React.Component {

  constructor(){
      super();
      this.contact_div_ref = React.createRef()  
      this.state = {
          job_index : 0 ,
      }
  }  


  changeJobIndex(index) {
      for( var i=0; i < jobArray.length; i++){
      
         if( index == i ) {
            document.getElementById('job_btn_'+i).style.backgroundColor = "white";
            this.setState({ job_index : index })
         }
         else{
            document.getElementById('job_btn_'+i).style.backgroundColor = '#f4f6f7';
         }
      }
  }


  submitQuery() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }


  componentDidMount(){
    window.scrollTo(0 , 0);
  }
  
  render() {
    return (
        <div className="col-12 p-0 m-0 ml-auto mr-auto" >

        {/* Breadcrum Div Contact Us */}
        <div className="breadcrumb_div"  >
                <h1 className="text_color_white text-center pt-5" > CONTACT US </h1>
                 <nav aria-label="breadcrumb ml-auto mr-auto">
                    <ol className="breadcrumb justify-content-center" style={{ backgroundColor : 'transparent' }}>
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active text_color_white" aria-current="page"> Contact Us </li>
                    </ol>
                 </nav>
         </div>
         
           {/* contact form and map */}
           <div className="col-12  ml-auto mr-auto row div_max_width " style={{ marginTop : '5%' }} >
                {/* map */}
                <div className="col-12 p-3 p-md-0  col-md-7 col-xl-8 ml-auto mr-auto "  >
                    <Map />
                </div>
                  
                {/* contact form  */}
                <div  className="col-12 col-sm-11 col-md-5 col-xl-4 pt-4 pt-md-0 ml-auto mr-auto text-center " >
                    <ContactForm  />
                </div>

           </div>


           {/* socail link and address */}
           <div className="col-12 col-lg-11 p-0 ml-auto mr-auto row div_max_width " style={{ marginTop : '5%' , marginBottom  : '5%' }}  >
              
              <div className="col-11 col-sm-5 col-lg-3 ml-auto mr-auto text-left mt-2 mb-2" >
              
                <h1 className="mb-0">7CTECH</h1>
                {/* <img src="https://res.cloudinary.com/seven-ctech/image/upload/v1625587510/website-Images/7ctech-black-logo_eowtvl.png" alt="footer_logo" /> */}
                {/* <br/> */}
                <p className="mt-2 text_color_grey " style={{fontSize : '16px' , letterSpacing: '0.05em' }} > Our prime focus is to offer flawless services over a longer period of time so that our customers can get maximum benefit. </p>
              
              </div>

              <div className={`col-11 col-sm-5 col-lg-3 p-1 ml-auto mr-auto text-left mt-2 mb-2 p-2 text_color_grey`} >

                    <i class="fas fa-map-marker-alt" style={{ fontSize : '16px'  }} > </i> Address :
                    <p className="mt-2" style={{ fontSize : '16px', fontFamily: 'Source Sans Pro'}}>  
                        252D, Shahrah-e-Faisal, P.E.C.H.S Block 2 Block 6 PECHS, <p></p>
                        Karachi 75400 Pakistan <p></p>
                    </p>

              </div>

              <div className="col-11 col-sm-5 col-lg-3 p-1 ml-auto mr-auto text-left mt-2 mb-2 text_color_grey"  >

                    <p  style={{ fontSize : '16px'  }}>
                        <i class="fas fa-phone mr-2"></i> Phone :
                        <a href="tel:+923422686008" style={{ color : 'rgb(15, 112, 168)' }}> +92-342-2686008</a>
                    </p>
                    <p  style={{ fontSize : '16px' }}>
                        <i class="far fa-envelope mr-2"></i> Email : 
                        <a href="mailto: info@7ctech.com" style={{ color : 'rgb(15, 112, 168)' }} > info@7ctech.com </a>
                    </p>
                    <p  style={{ fontSize : '16px'  }}>
                        <i class="fas fa-globe mr-2"></i> Website : 
                        <a href="/" style={{ color : 'rgb(15, 112, 168)' }} > www.7ctech.com </a>
                    </p>
              </div>

              <div className="col-11 col-sm-5 col-lg-3 p-1 ml-auto mr-auto text-left mt-2 mb-2 text_color_grey" >
                    <p><a className={`${styles.social_links} text_color_blue`} style={{ fontSize : '16px' , textDecoration: 'none' }}  href={fbUrl}> <i class="fas fa-map-marker-alt mr-2" ></i> Facebook </a></p>
                    <p><a className={`${styles.social_links} text_color_blue`} style={{ fontSize : '16px' , textDecoration: 'none' }}  href={twitterUrl}> <i class="fas fa-map-marker-alt mr-2" ></i> Twitter </a></p>
                    <p><a className={`${styles.social_links} text_color_blue`} style={{ fontSize : '16px' , textDecoration: 'none' }}  href={likedinUrl}> <i class="fas fa-map-marker-alt mr-2" ></i> LinkedIn </a></p>
                    {/* <p><a className={`${styles.social_links} text_color_blue`} style={{ fontSize : '16px' , textDecoration: 'none' }} > <i class="fas fa-map-marker-alt mr-2" ></i> Instagram </a></p> */}
              </div>

           </div>


         {/* black background div */}
         <div className={`${styles.balck_bg_image_div} d-flex align-content-center flex-wrap`}  >
            <div className="text-center col-12" >
                 <img  src={logo} />
              <h1 className="text_color_white text-center mt-5" >  YOUR PERFECT BUSINESS SOLUTION </h1>  
            </div> 
         </div>
         
         
         {/* team member and job sections */}
        
         <div className="col-12 col-lg-11 p-0 p-md-4 ml-auto mr-auto row div_max_width  " >
            
             {/* our team */}
              <div className="col-12 col-sm-11 col-md-4 mt-2 ml-auto mr-auto text-center " >

              <h5 style={{ fontWeight : 'bold' , fontSize : '140%' }} className="text-left text-md-left " >OUR TEAM </h5>
              
                <div className="col-12 p-0 m-0 ml-auto mr-auto mt-3 row" >

                    {  team.map((val , ind) => {
                        return(
                        <div className={`col-12  ml-auto ml-md-0 mr-auto mt-2 mb-2 text-center show_pointer row border rounded pt-5 pb-5 ${ styles.about_team_detail_div } `} >  
                            
                            <div className="col-3 col-md-12 col-lg-3 d-flex text-center "  >
                                <img src={val.image} class={`align-self-center ${styles.about_team_img} `}/>
                            </div>
                            
                            <div className="col-9 col-md-12 col-lg-9  text-left">
                                <h6 className={`text-left m-3 font-weight-light`} > { val.name } </h6>
                                <p className="text-left text_color_grey  ml-auto mr-auto pl-3 m-1" style={{ fontSize : '14px' }} > {val.position} </p>
                                <a className="ml-3 m-1 text-left text_color_blue" href={`mailto:${val.email}`} style={{textDecoration: 'none'}}> { val.email }  </a>
                            </div>
                            
                        </div>
                        )
                    })
                    }

                </div>
              
              </div>
                  
              {/* jobs   */}
               <div className="col-12 col-sm-11 col-md-8 mt-2  ml-auto mr-auto text-center " >
                     <h5 style={{ fontWeight : 'bold' , fontSize : '140%' }} className="text-left text-md-left" >OPEN VACANCIES </h5>

                     <div className="col-12 mr-auto text-left mt-3 pl-0 ml-0" >
                        <button id="job_btn_0" className={`${ styles.job_btn_active } ml-0`} onClick={()=>{ this.changeJobIndex(0) }} > FLUTTER / HYBRID MOBILE APP DEVELOPER </button>
                        <button id="job_btn_1" className={`${ styles.job_btn } ml-0`} onClick={()=>{ this.changeJobIndex(1) }} > SR.JAVASCRIPT DEVELOPER </button>
                        <button id="job_btn_2" className={`${ styles.job_btn } ml-0`} onClick={()=>{ this.changeJobIndex(2) }} > EXPERT SQA ANALYST </button>
                        <button id="job_btn_3" className={`${ styles.job_btn } ml-0`} onClick={()=>{ this.changeJobIndex(3) }} > PHP /JS FULL STACK DEVELOPER </button>
                     </div>

                     <div className="col-12 text-left ml-auto mr-auto border p-3 text_color_light_black mb-5" style={{ minHeight : '200px' }} >
                         <div className="danger_innerhtml" dangerouslySetInnerHTML={{__html: jobArray[this.state.job_index].job_description}} />
                     </div>

               </div>

           </div>

           {/* Blue div in contactus start */}

           <BlueDiv />

           {/* Blue div in contactus end */}
            
            {/* 
           <div  className={`col-12 mt-5 mb-5 ${ styles.about_gif_image_div } `} >
              <video autoplay="autoplay" loop="loop" src="http://www.7ctech.com/wp-content/uploads/2016/04/Working-it.mp4" muted="muted"></video>
           </div> */}

           <div className={`${styles.vidContainer} col-12 text-center`}>
            
                <div className={`${styles.vid_div} col-12`}>
                    <video playsInline className="border"  width="100%" autoplay="autoplay"  loop="loop"  muted="muted" style={{  height : '100%' , objectFit : 'cover' }} > <source src="https://res.cloudinary.com/seven-ctech/video/upload/v1625580647/website-Images/contact_video_m5axqa.mp4" /></video>
                </div>
            
                <div className={`${styles.vidDiv_content} col-12 align-self-center`}> 
                    <i className="far fa-arrow-alt-circle-down text_color_blue" style={{ fontSize : '80px' , fontWeight : 'lighter' }} ></i>
                    <h2 className="mt-4 mb-4 text_color_white" > NEED HELP? </h2>
                    <p className="text-center mt-3 mb-3 " style={{ fontSize : '16px' , color: 'white' , letterSpacing : '0.05em' }} >  Let’s build something incredible together! </p>
                    <p className="text-center mt-3 mb-3 col-11 col-md-8 col-xl-6 ml-auto mr-auto " style={{ fontSize : '16px' , color: 'white', maxWidth : "700px" }} >   Share your business ideas or needs with us and give us a chance to scale and transform your business with the best possible solutions and incredible services. </p>
                    
                    <div className="col-12 d-block d-md-flex justify-content-around ml-auto mr-auto" style={{ maxWidth : '700px' , marginTop : '3%'}}  >
                        <button className={`${styles.vid_div_submit_btn} m-2 mt-3 mb-3 mt-md-0 mb-md-0`} onClick={ ()=>{ this.submitQuery() } } >SUBMIT QUERY </button>
                        <a href="mailto: info@7ctech.com" style={{textDecoration: 'none' , color: 'white'}}><button className={`${styles.vid_div_write_btn} m-2 mt-3 mb-3 mt-md-0 mb-md-0`} > WRITE US TODAY</button></a>
                    </div>
                   
                </div>

            
            </div>

        </div>
    )
  }
} 


const jobArray = [
    
    {
        type : 'mobile' ,
        job_description : 
        `<p style="text-align: center;"><img src="https://res.cloudinary.com/seven-ctech/image/upload/v1625589860/website-Images/job-images_dkjwui.png" alt="sqa_image" width="50%" height="auto" style="max-height: 200px " /></p>
        <p style="text-align: center;">&nbsp;</p>
        <div class="jblk">
        <div class="jblk">
        <div dir="ltr">
        <div class="jblk">
        <h3 class="font18">JOB DESCRIPTION</h3>
        <div dir="ltr">
        <p style="text-align: left">We are looking for an experienced Hybrid Mobile App Developer (Flutter Developer) to assist us to design and build the next generation of our mobile applications.</p>
        <p>Ideal candidates must have a comprehensive knowledge and hands on experience of the Flutter.</p>
        <p>You should also be able to deploy Apps on both the iOS and Android platforms</p>
        <ul>
        <li>Must have hands on experience to 3rd party APIs (Communication &amp; Payment gateways etc)</li>
        <li>Experience launching and managing Production mobile Apps</li>
        <li>Good knowledge of MySQL, Firebase, JavaScript and Google APIs</li>
        <li>Understanding of the full mobile development life cycle</li>
        <li>Create and execute unit tests on your code, to ensure robustness, including edge cases, usability, and general reliability.</li>
        <li>Troubleshoot issues, fix bugs, improve application&rsquo;s performance</li>
        <li>Explore and utilize new technologies to maximize development efficiency</li>
        </ul>
        <p><strong>Requirements:&nbsp;</strong></p>
        <ul>
        <li>Translate designs and wire-frames into high quality code</li>
        <li>Ability to meet deadlines and manage multiple projects at once without sacrificing quality</li>
        <li>Proficient understanding of code versioning tools, such as GIT</li>
        <li>Must be team player</li>
        <li>Must know how to work under pressure</li>
        <li>Must know how to make documentation of code</li>
        <li>Must have can do and will do attitude.</li>
        <li>Must be willing to learn new skills.</li>
        </ul>
        </div>
        </div>
        <div class="jblk">
        <h4 class="font18">SKILLS</h4>
        <div class="jcnt font16">
        <p>Firebase, Flutter, Android Application, Engineering</p>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div class="jblk">
        <div class="jcnt font16">
        <div class="clearfix">&nbsp;</div>
        </div>
        </div>
        <div class="jblk">
        <h4 class="font18">JOB DETAILS</h4>
        <div class="jcnt jobd">

        <table class="table table-borderless col-11 col-lg-9">
        <tbody>
            <tr>
                <td style="font-weight:bold"> Industry: </td>
                <td> Information Technology </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Functional Area: </td>
                <td> Software & Web Development </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Total Positions: </td>
                <td> 2 Posts </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Shift: </td>
                <td> Second Shift (Afternoon) </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Type: </td>
                <td> Full Time/Permanent </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Location:</td>
                <td> Karachi, Pakistan </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Gender: </td>
                <td> No Preference </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Minimum Education: </td>
                <td> Bachelors </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Career Level: </td>
                <td> Experienced Professional </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Minimum Experience: </td>
                <td> 2 Years </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Apply Before: </td>
                 <td> ${job_end_date} </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Posting Date: </td>
                <td> ${job_post_date} </td>
            </tr>
        </tbody>
     </table>
    
     <div>
     <p>&nbsp;</p>
     <p><strong>How to apply:&nbsp;</strong></p>
     <p><strong>Email your resume with the position applied in subject to:&nbsp;<a href="mailto:info@7ctech.com">info@7ctech.com</a></strong></p>
     </div>
     </div>
     </div>
     </div>
        `
    },

    {
        type : 'JS' ,
        job_description : 
        `<p style="text-align: center;"><img src="https://res.cloudinary.com/seven-ctech/image/upload/v1625589860/website-Images/job-images_dkjwui.png" alt="sqa_image" width="50%" height="auto" style="max-height: 200px " /></p>
        <p style="text-align: center;">&nbsp;</p>
        <p style="text-align: center;">&nbsp;</p>
        <div class="jblk">
        <div class="jblk">
        <h3 class="font18">JOB DESCRIPTION</h3>
        <div dir="ltr">
        <p>We are looking for a Senior Javascript Developer with problem solving, self-learning, team work, quickly adaption to new technologies and mentoring skills along with the required technical skills.</p>
        <p>&nbsp;</p>
        <p><strong>Required Education &amp; Skills</strong>:</p>
        <ul>
        <li>Bachelor&rsquo;s or Master&rsquo;s Degree in Computer Science,</li>
        <li>Minimum of 3 years of experience in javascript frameworks and libraries.</li>
        <li>Minimum 4 years experience in&nbsp;PHP&nbsp; and its frameworks.</li>
        <li>Expert&nbsp;of HTML CSS3 and bootstrap</li>
        <li>Strong understanding of MVC framework</li>
        <li>Solid understanding of JavaScript functional programming concepts, object-oriented techniques</li>
        <li>Solid understanding of Data Structures, Algorithms, and Object-Oriented design</li>
        <li>Strong team work, self-learning and problem solving skills</li>
        <li>Good English communication skills</li>
        <li>Working knowledge of Restful APIs and web services</li>
        <li>Experience with node.js will be highly advantageous</li>
        </ul>
        </div>
        </div>
        <div class="jblk">
        <h4 class="font18">&nbsp;</h4>
        <h4 class="font18">SKILLS</h4>
        <div class="jcnt font16">
        <p>&nbsp; JavaScript, Jquery, HTML5, C++</p>
        </div>
        </div>
        </div>
        <div class="jblk">
        <div class="jcnt font16">
        <div class="clearfix">&nbsp;</div>
        </div>
        </div>
        <div class="jblk">
        <h4 class="font18">JOB DETAILS</h4>
        <div class="jcnt jobd">
        <div class="row>
        <div class="col-12 text-left"><strong>&nbsp; Industry:&nbsp; &nbsp; </strong>Information Technology</div>
        </div>
          
        
        <table class="table table-borderless col-11 col-lg-9">
        <tbody>
            <tr>
                <td style="font-weight:bold"> Industry: </td>
                <td> Information Technology </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Functional Area: </td>
                <td> Software & Web Development </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Total Positions: </td>
                <td> 2 Posts </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Shift: </td>
                <td> Second Shift (Afternoon) </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Type: </td>
                <td> Full Time/Permanent </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Location:</td>
                <td> Karachi, Pakistan </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Gender: </td>
                <td> No Preference </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Minimum Education: </td>
                <td> Bachelors </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Career Level: </td>
                <td> Experienced Professional </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Minimum Experience: </td>
                <td> 2 Years </td>
            </tr>
            <tr>
            <td style="font-weight:bold"> Apply Before: </td>
             <td> ${job_end_date} </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Posting Date: </td>
                <td> ${job_post_date} </td>
            </tr>
        </tbody>
     </table>

        <div>
        <p>&nbsp;</p>
        <p><strong>How to apply:&nbsp;</strong></p>
        <p><strong>Email your resume with the position applied in subject to:&nbsp;<a href="mailto:info@7ctech.com">info@7ctech.com</a></strong></p>
        </div>
        </div>
        </div>
        </div>
        `
    } ,

    {   
        type : "SQA" ,
        job_description : 
        `<p style="text-align: center;"><img src="https://res.cloudinary.com/seven-ctech/image/upload/v1625589860/website-Images/job-images_dkjwui.png" alt="sqa_image" width="50%" height="auto" style="max-height: 200px "  /></p>
        <p style="text-align: center;">&nbsp;</p>
        <p style="text-align: center;">&nbsp;</p>
        <div class="jblk">
        <h3 class="font18">JOB DESCRIPTION</h3>
        <div dir="ltr">
        <p>7CTECH is looking for a strong Intermediate SQA Analyst.</p>
        <p><strong>Responsibilities</strong>:</p>
        <ul>
        <li>Tests web applications to compare to specifications and documentation.</li>
        <li>Identifies, analyzes and documents defects, questionable functions, errors, and inconsistencies in web applications functions, outputs, online screens, and content.</li>
        <li>Establishes benchmarks for program efficiency in operating and response times.</li>
        <li>Performs web UI validation for cross browser compatibility.</li>
        <li>Creates &amp; executes test cases and test data.</li>
        <li>Contributes to establishment of standards and procedures for application design and testing.</li>
        <li>Documents, tracks, and communicates test plans, test results, analysis, and unresolved problems.</li>
        </ul>
        <p>&nbsp;</p>
        <div class="clearfix">&nbsp;</div>
        </div>
        </div>
        <div class="jblk">
        <h4 class="font18">SKILLS</h4>
        <div class="jcnt font16">
        <p>Black Box Testing, Automation Testing, Agile Testing, Database Applications, Software Quality Management</p>
        <div class="clearfix">&nbsp;</div>
        </div>
        </div>
        <div class="jblk">
        <h4 class="font18">JOB DETAILS</h4>
        
        
        <table class="table table-borderless col-11 col-lg-9">
        <tbody>
            <tr>
                <td style="font-weight:bold"> Industry: </td>
                <td> Information Technology </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Functional Area: </td>
                <td> Software & Web Development </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Total Positions: </td>
                <td> 2 Posts </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Shift: </td>
                <td> Second Shift (Afternoon) </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Type: </td>
                <td> Full Time/Permanent </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Location:</td>
                <td> Karachi, Pakistan </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Gender: </td>
                <td> No Preference </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Minimum Education: </td>
                <td> Bachelors </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Career Level: </td>
                <td> Experienced Professional </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Minimum Experience: </td>
                <td> 2 Years </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Apply Before: </td>
                <td> ${job_end_date} </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Posting Date: </td>
                <td> ${job_post_date} </td>
            </tr>
            </tbody>
        </table>


        <div>
        <p>&nbsp;</p>
        <p><strong>How to apply:&nbsp;</strong></p>
        <p><strong>Email your resume with the position applied in subject to:&nbsp;<a href="mailto:info@7ctech.com">info@7ctech.com</a></strong></p>
        </div>
        </div>
        </div>
        </div>`
    } ,

    {
        type : "PHP" ,
        job_description : 
        `<p style="text-align: center;"><img src="https://res.cloudinary.com/seven-ctech/image/upload/v1625589860/website-Images/job-images_dkjwui.png" alt="sqa_image" width="50%" height="auto" style="max-height: 200px " /></p>
        <p style="text-align: center;">&nbsp;</p>
        <p style="text-align: center;">&nbsp;</p>
        <div class="jblk">
        <h3 class="font18">JOB DESCRIPTION</h3>
        <div dir="ltr">
        <p>We are looking for a Full Stack Developer with problem solving, self-learning, team work, quickly adaption to new technologies and mentoring skills along with the required technical skills.</p>
        <div class="jblk">
        <div dir="ltr">
        <p>&nbsp;</p>
        <p><strong>Required Education &amp; Skills:</strong></p>
        <ul>
        <li>Bachelor&rsquo;s or Master&rsquo;s Degree in Computer Science,</li>
        <li>Minimum of 3 years of experience in javascript frameworks and libraries.</li>
        <li>Minimum 3 years experience in&nbsp;PHP&nbsp; and its frameworks.</li>
        <li>Expert&nbsp;of HTML CSS3 and bootstrap</li>
        <li>Strong understanding of MVC framework</li>
        <li>Solid understanding of JavaScript functional programming concepts, object-oriented techniques</li>
        <li>Solid understanding of Data Structures, Algorithms, and Object-Oriented design</li>
        <li>Strong team work, self-learning and problem solving skills</li>
        <li>Good English communication skills</li>
        <li>Working knowledge of Restful APIs and web services</li>
        <li>Experience with node.js will be highly advantageous</li>
        </ul>
        </div>
        </div>
        <div class="jblk">
        <h4 class="font18">SKILLS</h4>
        <div class="jcnt font16">
        <p>API Development, Core PHP, HTML</p>
        </div>
        </div>
        </div>
        </div>
        <div class="jblk">
        <div class="jcnt font16">
        <div class="clearfix">&nbsp;</div>
        </div>
        </div>
        <div class="jblk">
        <h4 class="font18">JOB DETAILS</h4>
      
        
        <table class="table table-borderless col-11 col-lg-9">
        <tbody>
            <tr>
                <td style="font-weight:bold"> Industry: </td>
                <td> Information Technology </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Functional Area: </td>
                <td> Software & Web Development </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Total Positions: </td>
                <td> 2 Posts </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Shift: </td>
                <td> Second Shift (Afternoon) </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Type: </td>
                <td> Full Time/Permanent </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Job Location:</td>
                <td> Karachi, Pakistan </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Gender: </td>
                <td> No Preference </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Minimum Education: </td>
                <td> Bachelors </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Career Level: </td>
                <td> Experienced Professional </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Minimum Experience: </td>
                <td> 2 Years </td>
            </tr>
            <tr>
            <td style="font-weight:bold"> Apply Before: </td>
             <td> ${job_end_date} </td>
            </tr>
            <tr>
                <td style="font-weight:bold"> Posting Date: </td>
                <td> ${job_post_date} </td>
            </tr>
        </tbody>
     </table>

        <div>
        <p>&nbsp;</p>
        <p><strong>How to apply:&nbsp;</strong></p>
        <p><strong>Email your resume with the position applied in subject to:&nbsp;<a href="mailto:info@7ctech.com">info@7ctech.com</a></strong></p>
        </div>
        </div>
        </div>
        </div>`
    }
   
]


export default Contact;