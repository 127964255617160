import React from 'react';
 
function Contact() {

  return (
      <div className="col-11 col-md-11 ml-auto mr-auto  text-left p-0 m-0">
          <h6 className="text-left font-weight-bold text_color_white col-11" > CONTACT US </h6>
          <br/>
          <br/>

          <div className="col-12 col-md-11 text-left" style={{color:'#89a9b5'}} >
              
            <i class="fas fa-map-marker-alt" style={{ fontSize : '14px' , color:'#89a9b5' }} > </i> Address :
            
            <p className="mt-2" style={{ color:'#89a9b5' , fontSize: '14px'}}>  
              252D, Shahrah-e-Faisal, P.E.C.H.S Block 2 Block 6 PECHS, <br/>
              Karachi 75400 Pakistan<br/>
            </p>

            <hr style={{ backgroundColor  :'gray' }}/>

            <p  style={{  color:'#89a9b5' , fontSize : '13px' }}>
              <i class="fas fa-phone mr-2"style={{ color:'#89a9b5' }}></i> Phone: 
              <a href="tel:+923422686008" style={{ color : 'rgb(33,163,196)' }} > +92-342-2686008 </a>
            </p>
              
            <p  style={{ color:'#89a9b5' , fontSize : '13px'}}>
              <i class="far fa-envelope mr-2"></i> Email : 
              <a href="mailto: info@7ctech.com" style={{ color : 'rgb(33,163,196)' }} > info@7ctech.com </a>
            </p>

            <p  style={{  color:'#89a9b5' , fontSize : '13px' }}>
              <i class="fas fa-globe mr-2"></i> Website : 
              <a href="/" style={{ color : 'rgb(33,163,196)' }} > www.7ctech.com </a>
            </p>


          </div>

           
      </div>
  );
}
 
export default Contact;