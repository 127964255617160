import React from 'react';
import styles from '../../styles/home/contact_us.module.css'
import fetch  from 'isomorphic-fetch'
import Recaptcha from 'react-recaptcha';

// create a variable to store the component instance
let recaptchaInstance;


class Contactus extends React.Component {
    
    constructor(){
        super()
        this.state = {
            phone : '' ,
            name : '' ,
            email : '',
            message : '',
            show_responce_msg : false,
            responce_msg : '' ,
            recaptcha_verified : false
        }
        
        this.recaptchaload = this.recaptchaload.bind(this);
        this.verifyCallback =this.verifyCallback.bind(this);
        
        
    }    
    
    
    submitForm(e) {
        
        // const client = new SMTPClient({
        //     user: 'info@7ctech.com',
        //     password: '1123581321',
        //     host: 'secure195.servconfig.com',
        //     ssl: true,
        // });
        const { name , phone , email  , message , recaptcha_verified } = this.state;
    
        // console.log(`NAME ${name} , email ${email} phone ${phone}  , message ${message}`)
        
        if(name == '' || phone == '' || email == ''  || message == ''){
            this.setState({ show_responce_msg : true , responce_msg : 'Please fill out all field' })
        }
        else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
        {
            this.setState({ show_responce_msg : true , responce_msg : 'Please write valid email address' })
        }
        else if (!/^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(phone))
        {
            this.setState({ show_responce_msg : true , responce_msg : 'Please enter valid mobile number' })
        }
        // else if ( recaptcha_verified == false )
        // {
        //     this.setState({ show_responce_msg : true , responce_msg : 'Please verify Recaptcha' })
        // }
    
        else { 
            

            // client.send(
            //     {
            //         text: 'i hope this works',
            //         from: 'info@7ctech.com',
            //         to: 'shahjahan.7ctech@gmail.com',
            //         subject: 'Contact Us Email',
            //     },
            //     (err, message) => {
            //         console.log(err || message);
            //     }
            // );
            document.getElementById("submit_btn").disabled = true;
            
            fetch('https://server-7ctech.herokuapp.com/send' , {
                method : 'POST' ,    
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name : name ,
                    email : email,
                    phone : phone ,
                    interest : '' ,
                    message : message
                  }) 
                })  
                .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success == false  ) {
                            // console.log('error email' , data)
                            document.getElementById("submit_btn").disabled = false;
                            recaptchaInstance.reset();
                            this.setState({ show_responce_msg : true , responce_msg : data.message  })
                        }
                        else {
                            // console.log('success email' , data)
                            document.getElementById("submit_btn").disabled = false;
                            // recaptchaInstance.reset();
                            this.setState({ 
                                show_responce_msg : true , 
                                responce_msg : data.message , 
                                name : '' , 
                                phone : '' ,
                                email : '' ,
                                interest : '' ,
                                message : '' ,
                            })
                        }
                    }).catch((err) => {
                        
                        document.getElementById("submit_btn").disabled = false;
                        this.setState({ 
                            show_responce_msg : true , 
                            responce_msg : "Some thing went wrong in submiting form. Please try later"
                        })
                        // console.log('Error', err) 
                    })
                }
        }
        
 
        recaptchaload(){
            console.log('captcha loaded sucessfully');
        }
    
        verifyCallback(response){
            console.log(' recaptcha responce' , response)
            if(response){
              this.setState({
                recaptcha_verified : true
              })
            }
          }
 
  render() {
   return (
        <>
           <div className={`col-12 p-0 pt-5 pt-md-0 m-0   ${styles.contact_us_main_div} `}   >
              
           <h4 className="col-12  text-center text_color_blue font-weight-bold" style={{ padding : '7% 2px 4% 2px'  }} >  CONTACT US  </h4>  
              
              <div className="col-12 col-lg-11 pt-5 pt-md-1 pb-5 row ml-auto mr-auto div_max_width">
                  
                  <div className="col-12 col-md-6 col-lg-5 col-xl-5 ml-auto mr-auto p-0" >

                     <ul className={`${styles.contact_us_text}`} style={{color: 'grey' , fontSize: '17px'}}>
                         <li  style={{ color : 'grey' }}> 252D, Shahrah-e-Faisal, P.E.C.H.S Block 2 Block 6 PECHS, Karachi 75400 Pakistan </li>
                         <li className=" mt-2 mb-2" > Phone : <a className={`text_color_blue font-weight-light `} style={{textDecoration: 'none'}} href="tel:+923422686008"> +92-342-2686008 </a></li>
                         <li className=" mt-2 mb-2" > Email : <a className="text_color_blue show_pointer" href="mailto:info@7ctech.com" style={{textDecoration: 'none'}}> info@7ctech.com </a> </li>
                         <li className=" mt-2 mb-2" > Website : <a className="text_color_blue show_pointer" href="/" style={{textDecoration: 'none'}}>  www.7ctech.com </a> </li>
                     </ul>

                  </div>

                  <div className="col-12 col-md-6 col-lg-5 col-xl-5 ml-auto mr-auto text-left p-2 m-0">

                        <div className="input-group col-md-12">
                            <input className={`form-control shadow-none border ${styles.contact_us_input_style}`} placeholder="Name * " value={this.state.name} onChange={ (e) => this.setState({ name : e.target.value }) } />
                        </div>
                    

                        <div className="input-group col-md-12 mt-4">
                            <input className={`form-control  border-right-0  shadow-none border ${styles.contact_us_input_style}`}  placeholder="Email * "  value={this.state.email}  onChange={ (e) => this.setState({ email : e.target.value }) } />
                            <span className="input-group-append">
                                <button className={`btn border border-left-0 shadow-none show_aero ${styles.contact_us_input_btn}`} type="button" >
                                    <i className="far fa-envelope" style={{ color:'gray' }} ></i>
                                </button>
                            </span>
                        </div>

                        <div className="input-group col-md-12 mt-4">
                            <input className={`form-control  border-right-0  shadow-none border ${styles.contact_us_input_style}`}  placeholder="Your Phone * " value={this.state.phone} onChange={ (e) => this.setState({ phone : e.target.value }) } />
                            <span className="input-group-append">
                                <button className={`btn border border-left-0 shadow-none show_aero ${styles.contact_us_input_btn}`} type="button" >
                                    <i className="fas fa-phone" style={{ color:'gray' }} ></i>
                                </button>
                            </span>
                        </div>


                        <div className="input-group col-md-12 mt-4">
                            <textarea className={`form-control shadow-none border ${styles.contact_us_input_style}`} style={{ minHeight:'100px' }} placeholder="Message * " value={this.state.message} onChange={ (e) => this.setState({ message : e.target.value }) } />
                        </div>

                        
                         {/* <div className="col-12 p-3 text-center" style={{  minHeight : '100px'}}  > 
                            <Recaptcha 
                                ref={e => recaptchaInstance = e}
                                sitekey="6LeZh2QaAAAAAJxh1bq4e6XUtVmr8UiWYjLy7ynP"
                                render="explicit"
                                onloadCallback={this.recaptchaload}
                                verifyCallback={this.verifyCallback}
                            />
                        </div> */}

                        {/* testing key
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" */}

                    
                        <div className="input-group col-md-12 mt-4">
                            <button className={`btn  col-12 shadow-none ${styles.contact_us_btn}`} onClick={() => { this.submitForm() }  }  id="submit_btn"  > SUBMIT   </button>
                        </div>

                        {
                            this.state.show_responce_msg &&
                            <p className="p-2 m-1 m-3 text_color_white" style={{ border : 'solid 1px #ffba43' , minHeight : '100px' , fontSize : '15px'}}  > { this.state.responce_msg } </p>
                        }

                </div>
              </div>
           </div>
        </>
    )
  }
}
 
export default Contactus;


